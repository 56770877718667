export default {
  __name: 'loginwx',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    onMounted(() => {
      let code = route.query.code;
      let state = proxy.$common.basic.chStr2Int(route.query.state);
      if (state > 0 && state == proxy.$store.wxState) {
        let jsondata = {
          'postdata': {
            'api': 'member',
            'act': 'login',
            'data': {
              'wxcode': code
            }
          },
          'msg': '正在登录...'
        };
        proxy.$common.post(jsondata, res => {
          proxy.$store.setIsLogin(1);
          proxy.$store.setIsBind(res.isbind);
          proxy.$store.setMemLev(res.lev);
          window.location = '/';
          /*if (res.isbind == 1) {
              window.location = '/';
          }
          else {
              window.location = '/login';
          }*/
        }, () => {
          window.location = '/login';
        });
      } else {
        window.location = '/login';
      }
    });
    return (_ctx, _cache) => {
      return null;
    };
  }
};